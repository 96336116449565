
export const SUBTRACT_GAS_LIMIT = 100000

export const networkId = 11221

export const contractAddresses = {
  remit: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  weth: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  remitStake: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  remitVaultFarmEth: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  stake2: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  stake3_5: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
  stake3: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  },
}

export const supportedPools = []

export const farmingPools = { 
  remitETH: {
    11221: '0x772F67974a9522a6171aA1A5A50C21C1Afd23d33',
  }
}

